import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/jonespen/projects/urbaninfrastructure/react-ui-kit/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from "docz";
import { Arrow } from "@urbaninfrastructure/react-ui-kit";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "arrow"
    }}>{`Arrow`}</h1>
    <h2 {...{
      "id": "default"
    }}>{`Default`}</h2>
    <Playground __position={0} __code={'<Arrow direction=\"up\" />\n<Arrow direction=\"down\" />\n<Arrow direction=\"right\" />\n<Arrow direction=\"left\" />\n<Arrow degrees=\"60\" color=\"primary\" />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Arrow,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <Arrow direction="up" mdxType="Arrow" />
  <Arrow direction="down" mdxType="Arrow" />
  <Arrow direction="right" mdxType="Arrow" />
  <Arrow direction="left" mdxType="Arrow" />
  <Arrow degrees="60" color="primary" mdxType="Arrow" />
    </Playground>
    <h2 {...{
      "id": "component-props"
    }}>{`Component props`}</h2>
    <Props of={Arrow} mdxType="Props" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      